/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import LineFull from '../images/linefull.jpg';
import Greenlogo from '../images/greenlogo.jpg';
import SEO from '../components/seo';
import '../styles/app.scss';
import '../styles/_theme_fix_pages.css';

const campsummertf = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[0].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />

            <style>
                {`
             .all-popup {
display:  !important;
    visibility: hidden;
    z-index: -2 !important;
             } 
        `}
            </style>

            <section className="page-section smallestwdt notbottompaddsec nopaddbottsec">

                <div className="container">


                    <div
                        className="openprgt middle-text"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_content_right,
                        }}
                    />
                    {/* <h3 className='big-title_deals'>From Dec 25th, unwrap a new offer each day, making your holidays merrier than ever!</h3> */}
                    <div className="clearall"></div>
                </div>
            </section>

            <div className="container text_center mt-15">
                <img src={LineFull} alt="linefull" loading="lazy" />
            </div>
            <section className="page-section smallestwdt notbottompaddsec nopaddbottsec">

                <div className="container">


                    <div
                        className="openprgt middle-text"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_content_second,
                        }}
                    />
                    {/* <h3 className='big-title_deals'>From Dec 25th, unwrap a new offer each day, making your holidays merrier than ever!</h3> */}
                    <div className="clearall"></div>
                </div>
            </section>

            <div className="container text_center mt-15 padd-70" id="setdates">
                <img src={LineFull} alt="linefull" loading="lazy" />
            </div>

            <section className="page-section smallestwdt notbottompaddsec nopaddbottsec">

                <div className="container">

                    <h3 className='big-title_deals'>Set Dates, Big Savings!</h3>
                    <p className="p-margin text_center">Book specific dates early for the best price and exclusive discounts. Guarantee your child's spot and enjoy stress-free planning.</p>
                    <div class="weeks-flex sm-table">
                        <div><span></span><span className='memebers_bold'>Members</span><span className='memebers_bold'>Non-Members</span></div>
                        <div><span>4-Days - 9:00AM - 1:00PM</span><span>$225.00</span><span>$240.00</span></div>
                        <div><span>5-Days - 9:00AM - 1:00PM</span><span>$280.00  </span><span>$300.00</span></div>
                    </div>

                    <div className="booked_box">
                        <a className="heal-bp wrtsbtn yellowbtn open_btned" data-popup="sctf_popup">Book Now</a>
                    </div>

                    <p className="p-margin text_center"><a href="https://form.jotform.com/240350239738154" target="_blank">Looking to Book Select Dates Each Week Click Here</a></p>
                    <div className="clearall"></div>
                </div>
            </section>

            <div className="container text_center mt-15 padd-70" id="flexibility-unleashed">
                <img src={LineFull} alt="linefull" loading="lazy" />
            </div>

            <section className="page-section openphours camps-flexibility">
                <div className="container smallestwdt">

                    <h3 className='big-title_deals'>Flexibility Unleashed!</h3>
                    <p className="p-margin text_center">Purchase discounted passes and choose dates that fit your schedule. Ideal for families with dynamic plans - adapt your summer seamlessly.</p>
                    <div className='summer-camp-flex'>
                        <h5><span>3-Day Pass</span> <br />
                            <span>Member Flex $180 - <div
                                className="healwrap small_heals"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf.healcode_single_member_three_day,
                                }}
                            /></span> <br />
                            <span>Flex $195 - <div
                                className="healwrap small_heals"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf.healcode_single_nonemember_three_day,
                                }}
                            /></span></h5>

                        <h5><span>6-Day Pass</span> <br />
                            <span>Member Flex $360 - <div
                                className="healwrap small_heals"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf.healcode_single_member_six_day,
                                }}
                            /></span> <br />
                            <span>Flex $390 - <div
                                className="healwrap small_heals"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf.healcode_single_nonemember_six_day,
                                }}
                            /></span></h5>

                        <h5><span>9-Day Pass</span><br />
                            <span>Member Flex $540 - <div
                                className="healwrap small_heals"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf.healcode_single_member_nine_day,
                                }}
                            /></span> <br />
                            <span>Flex $585 -<div
                                className="healwrap small_heals"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf.healcode_single_nonemember_nine_day,
                                }}
                            /></span> </h5>

                        <h5><span>12-Day Pass</span> <br />
                            <span>Member Flex $695 - <div
                                className="healwrap small_heals"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf.healcode_single_member_twelve_day,
                                }}
                            /></span> <br />
                            <span>   Flex $720 - <div
                                className="healwrap small_heals"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf.healcode_single_nonemember_twelve_day,
                                }}
                            /></span></h5>

                    </div>
                    {/* <div className="container text_center mt-15 padd-70">
                        <img src={LineFull} alt="linefull" loading="lazy" />
                    </div>   
                    <p className="p-margin text_center">Click Here to <a href="https://werockthespectrumatlantagym.wrtsfranchise.com/wp-content/uploads/2024/01/WRTS_Roswell_Atlanta_Summer_Camp_2024.pdf" target="_blank">Learn More</a></p> */}



                    {/* <div className="onethirdcol full-row_heals hot_deal_healcode">

                    

                    </div>
                    <div className="container text_center mt-15">
                        <img src={LineFull} alt="linefull" loading="lazy" />
                    </div>
                    <p className='titles_for-Heal sec_heal_title'><span className='bluetxt'>Open Play Passes</span>
                        <br />
                        <br />
                        <span className='bluetxt'>BUY 3 GET 1 FREE</span>
                    </p>
                    <div className="onethirdcol full-row_heals hot_deal_healcode">

                    

                    </div>
                    <div className="container text_center mt-15">
                        <img src={LineFull} alt="linefull" loading="lazy" />
                    </div>
                    <div className='hot_deal_healcode_wrap'>
                        <p className='titles_for-Heal sec_heal_title'><span className='bluetxt'>Camps
                            <br />
                            <br />
                            Early Bird Pricing</span> </p>
                        <p className="hd-info">$55 per Day for Multi-Day Camps <br />
                            $80 per Day for Multi-Day Camps with After-Care</p>
                        <a className='healcode-pricing-option-text-link wrtsbtn yellowbtn healcode-register' href="/camp/">Sign Up</a>
                    </div> */}
                    {/* <div className="container text_center mt-15">
                        <img src={LineFull} alt="linefull" loading="lazy" />
                    </div> */}
                    {/* <p className='titles_for-Heal sec_heal_title'><span className='bluetxt'>Drop Off Care</span>
                    </p> */}
                    {/* <div className="onethirdcol full-row_heals hot_deal_healcode">

                      

                    </div> */}
                    {/* <div className="container text_center mt-15">
                        <img src={LineFull} alt="linefull" loading="lazy" />
                    </div>
                    <p className='titles_for-Heal sec_heal_title'><span className='bluetxt'>Monthly Class Pass



                        <br /><br />
                        Unlimited Classes for 1 Month</span>
                    </p> */}
                    {/* <div className="onethirdcol full-row_heals hot_deal_healcode">

                    

                    </div> */}
                    {/* <div className="container text_center mt-15">
                        <img src={LineFull} alt="linefull" loading="lazy" />
                    </div> */}
                    {/* <p className='titles_for-Heal sec_heal_title'><span className='bluetxt'>Open Play Monthly Rockstar Membership

                        <br /><br />
                        Unlimited 2 Hour Drop-in's for Open Play each Month
                        <br />
                        Valid for 4 Months
                        <br />
                        1 Guest Pass Every Month</span>
                        <br />
                        <br />
                        <span className='bluetxt'>BUY 3 GET 1 FREE</span>
                    </p> */}
                    {/* <div className="onethirdcol full-row_heals hot_deal_healcode">

                    

                    </div> */}

                    <div className='new_reg_place display_none'>
                        <a
                            href="/"
                            style={{ background: 'none', padding: 0 }}
                        >

                        </a>
                    </div>

                </div>
            </section>

            <section className="page-section bg-secondary text-white centersec">
                <div className="container smallestwdt">
                    <p className="p-margin text_center p-with-link">Click Here to <a className='text-white-a' href="https://drive.google.com/file/d/1iXgQhDJ8cAaAB2DDrOVMy31_NHCH29_0/view?usp=drive_link" target="_blank">Learn More</a></p>
                </div>
            </section>

            <div className="popup-wrap sctf_popup">
                <div className="popup">
                    <span className="close_popup">×</span>

                    <iframe src="https://werockthespectrumatlantagym.wrtsfranchise.com/sctf_offers.php" title="appointment" className="healcode_widgets" />

                </div>
            </div>
            {/* <div className="popup-wrap se_popup_pno_two">
                <div className="popup">
                    <span className="close_popup">×</span>

                    <iframe src="https://werockthespectrumatlantagym.wrtsfranchise.com/se_parents_two.php" title="appointment" className="healcode_widgets" />

                </div>
            </div>
            <div className="popup-wrap se_popup_pno_three">
                <div className="popup">
                    <span className="close_popup">×</span>

                    <iframe src="https://werockthespectrumatlantagym.wrtsfranchise.com/se_parents_three.php" title="appointment" className="healcode_widgets" />

                </div>
            </div> */}


            {/* <section className="page-section bg-secondary text-white centersec">
<div className="container smallestwdt">

<h5>MONDAY - SATURDAY: 9AM - 6PM</h5>
<h5>SUNDAY: 10AM - 4:30PM</h5>

</div>
</section> */}




            <Footer />
        </>
    );
};

export default campsummertf;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            acf {
                open_play_content_right
                open_play_content_second
                healcode_single_member_three_day
                healcode_single_nonemember_three_day
                healcode_single_member_six_day
                healcode_single_nonemember_six_day
                healcode_single_member_nine_day
                healcode_single_nonemember_nine_day
                healcode_single_member_twelve_day
                healcode_single_nonemember_twelve_day
            
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
